import React from 'react';
import Iframe from 'react-iframe';

const InventoryPage = () => (
  <Iframe
    src="https://www.zakopane-mbp.sowa.pl/sowacgi.php?KatID=0&new=1&lang=pl_PL"
    width="100%"
    height="100%"
    position="absolute"
  />
);
export default InventoryPage;
